<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :headers="headers"
          :items="mutations"
          :page="page"
          :search="search"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          title="Kelulusan"
          subtitle="Halaman untuk kelulusan siswa "
          add-btn="Tambah Data"
          :edit-btn="false"
          @delete-item="confirmDestroy"
          @add-item="showFormAdd"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @handle-point="showFormHandlePoint"
          @show-point="showPoint"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialogPoint"
      :width="width"
      :is-loading-button="isLoadingButton"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Masukkan Nilai': 'Edit Nilai'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialogPoint = false"
      @close-button="modalDialogPoint = false"
    >
      <template
        v-slot:body
      >
        <v-row>
          <v-col md="6">
            <h4 class="mb-4">
              INFORMASI SISWA
            </h4>
            <div class="d-inline-block">
              <p class="font-weight-bold black--text">
                NIS
              </p>
              <p class="font-weight-bold black--text">
                Nama
              </p>
            </div>
            <div class="d-inline-block ms-4">
              <p>: {{ mutationGrade.nis }}</p>
              <p>: {{ mutationGrade.full_name }}</p>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="my-2">
          <v-col md="8">
            <h4>MATA PELAJARAN</h4>
            <span>Mata pelajaran yang di ikuti siswa</span>
          </v-col>
          <v-col md="4">
            <h4>NILAI</h4>
            <span>Nilai 1-100</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(subject,index) in subjects"
            :key="index"

            md="12"
          >
            <div class="d-flex">
              <div style="width: 80%">
                <v-text-field
                  v-model="subject.subject"
                  label="Mata Pelajaran"
                  outlined
                  dense
                  class="textfieldHidden"
                ></v-text-field>
              </div>
              <div
                style="width: 20%"
                :class="index > 4 ? 'mx-4' : 'ml-4'"
              >
                <v-text-field
                  v-model="subject.grade"
                  label="Nilai"
                  outlined
                  dense
                  class="textfieldHidden"
                  type="number"
                ></v-text-field>
              </div>
              <v-btn
                v-if="index > 4"
                small
                icon
                color="primary"
                class="mt-1"
                @click="deleteSubject(index, subject.uuid)"
              >
                <v-icon>
                  {{ icons.mdiCloseCircleOutline }}
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <div
          v-if="dialog === 'add' || dialog ==='edit'"
          class="mt-4"
        >
          <a
            class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-sm"
            @click.prevent="addSubject"
          >TAMBAH MATA PELAJARAN</a>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDialogShowPoint"
      :width="width"
      :is-loading-button="isLoadingButton"
      header="Nilai Kelulusan Mahasiswa"
      :hidden-actions="false"
      @close-modal="modalDialogShowPoint = false"
      @close-button="modalDialogShowPoint = false"
    >
      <template
        v-slot:body
      >
        <v-row>
          <v-col md="6">
            <h4 class="mb-4">
              INFORMASI SISWA
            </h4>
            <div class="d-inline-block">
              <p class="font-weight-bold black--text">
                NIS
              </p>
              <p class="font-weight-bold black--text">
                Nama
              </p>
            </div>
            <div class="d-inline-block ms-4">
              <p>: {{ mutationGrade.nis }}</p>
              <p>: {{ mutationGrade.full_name }} </p>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="my-2">
          <v-col md="8">
            <h4>MATA PELAJARAN</h4>
            <span>Mata pelajaran yang di ikuti siswa</span>
          </v-col>
          <v-col md="4">
            <h4>NILAI</h4>
            <span>Nilai 1-100</span>
          </v-col>
        </v-row>

        <v-row
          v-for="(mutationGrade,index) in mutationGrades"
          :key="index"
          class="py-0"
        >
          <v-col
            md="8"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="mutationGrade.subject"
                label="Mata Pelajaran"
                outlined
                :disabled="dialog ==='show'"
                dense
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            md="4"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="mutationGrade.grade"
                label="Nilai"
                outlined
                :disabled="dialog ==='show'"
                dense
                type="number"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :dialog="dialog"
      header="Tambah Kelulusan Siswa"
      :hidden-actions="false"
      :text-button="dialog ==='add'? 'Tambah' : 'Update'"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template
        v-slot:body
      >
        <v-row>
          <v-col md="6">
            <h4 class="mb-4">
              Pilih kelas yang akan diluluskan
            </h4>
            <div>
              <v-autocomplete
                v-model="class_uuid"
                label="Pilih Kelas"
                outlined
                :items="classes"
                item-text="name"
                item-value="uuid"
                dense
                clearable
                @change="filterByClass($event)"
              >
              </v-autocomplete>
            </div>
          </v-col>
        </v-row>
        <v-card>
          <v-card-title>
            <h5>Data Siswa/i</h5>
            <v-spacer></v-spacer>
            <small>{{ total_student }} Dipilih</small>
          </v-card-title>
          <v-simple-table
            class="overflow-y-auto mb-6"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <v-checkbox v-model="selectAll"></v-checkbox>
                  </th>
                  <th class="text-left">
                    Nis
                  </th>
                  <th class="text-left">
                    Nama Siswa
                  </th>
                </tr>
              </thead>
              <v-progress-linear
                v-if="isLoadingSimpleTable"
                indeterminate
                color="primary"
                :absolute="true"
                :rounded="true"
              ></v-progress-linear>
              <tbody v-else>
                <tr
                  v-for="(student,index) in students"
                  :key="index"
                >
                  <td>
                    <v-checkbox
                      v-model="selected"
                      :value="student.uuid"
                      @change="handleCheckboxChange(student.uuid, selected)"
                    ></v-checkbox>
                  </td>
                  <td>{{ student.nis }}</td>
                  <td>{{ student.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            large
            :value="status[0].title"
            :loading="isLoadingButtonGraduate"
            :disabled="!class_uuid || !selected.length"
            @click="addGraduated(status[0].title)"
          >
            Lulus
          </v-btn>
          <v-btn
            color="primary"
            outlined
            large
            :value="status[1].title"
            :loading="isLoadingButtonNotGraduate"
            :disabled="!class_uuid || !selected.length"
            @click="addGraduated(status[1].title)"
          >
            Tidak Lulus
          </v-btn>
        </v-card-actions>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Kelulusan"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    ></ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { integerValidator, required } from '@core/utils/validation'
import { mdiCloseCircleOutline } from '@mdi/js'

export default {
  name: 'Studentgraduate',
  components: {
    MainCard,
    ModalDialog,
    ConfirmDialog,
    SkeletonLoaderTable,
    DataTablePagination,
  },
  data() {
    return {
      dialog: '',
      search: '',
      width: 600,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      total_student: 0,
      modalDialog: false,
      modalDialogPoint: false,
      modalDialogShowPoint: false,
      confirmDialog: false,
      isLoadingSimpleTable: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      isLoadingButtonGraduate: false,
      isLoadingButtonNotGraduate: false,
      icons: {
        mdiCloseCircleOutline,
      },
      buttons: [
        { title: 'Lulus', value: 'Lulus', class: 'primary v-size--large' },
        { title: 'Tidak Lulus', value: 'Tidak Lulus', class: 'v-btn--outlined v-size--large primary--text' },
      ],
      validation: {
        required,
        integerValidator,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nis', value: 'student.nis' },
        { text: 'Nama Siswa', value: 'student.full_name' },
        { text: 'Status', value: 'status' },
        { text: 'Nilai', value: 'show-point' },
        { text: '', value: 'handle-point' },
        { text: 'Actions', value: 'actions' },
      ],
      subjects: [],
      subject: {
        subject: '',
        grade: '',
      },
      service: 'mutationgrade',
      subjectContent: [],
      mutations: [],
      students: [],
      formValid: false,
      date_out: false,
      school_uuid: '',
      student_uuid: '',
      mutation_uuid: '',
      mutation: {
        student: {},
      },
      mutationGrades: [
        { subject: '', grade: '' },
        { subject: '', grade: '' },
        { subject: '', grade: '' },
        { subject: '', grade: '' },
        { subject: '', grade: '' },
      ],
      mutationGradeContent: [],
      mutationGrade: {
        student: {},
      },
      school: [],
      class_uuid: null,
      classes: [],
      graduate: {
        date_out: '',
      },
      filterQuery: {
        search: '',
      },
      selected: [],
      status: [{ title: 'Lulus' }, { title: 'Tidak Lulus' }],
    }
  },
  computed: {
    selectAll: {
      get() {
        return this.total_student ? this.selected.length === this.students.length : false
      },
      set(value) {
        const selected = []
        if (value) {
          this.students.forEach(student => {
            selected.push(student.uuid)
            this.total_student += 1
          })
        } else {
          this.selected = []
          this.total_student = 0
        }
        this.selected = selected
      },
    },
  },

  watch: {
    class_uuid: {
      handler() {
        this.listStudent()
      },
    },
    page: {
      handler() {
        this.listMutationGrade(this.filterQuery)
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.listClass()
    this.listMutationGrade()
  },
  methods: {
    resetForm() {
      this.mutationGrade.subject = ''
      this.mutationGrade.grade = ''
      this.total_student = 0
      this.selected = []
      this.class_uuid = null
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        this.school_uuid = el.uuid
      })

      return true
    },
    async listClass(params = {}) {
      await this.$services.ApiServices.list('class', {
        ...params,
        school_uuid: this.school_uuid,
        per_page: 'all',
      }).then(
        ({ data }) => {
          this.classes = data.data
        },
        err => console.error(err),
      )
    },
    async listStudent(params = {}) {
      this.isLoadingSimpleTable = true
      await this.$services.ApiServices.list('student', {
        ...params,
        per_page: 'all',
        class: this.class_uuid,
        school_uuid: this.school_uuid,
      }).then(
        ({ data }) => {
          this.students = data.data
        },
        err => console.error(err),
      )
      this.isLoadingSimpleTable = false
    },

    async listMutationGrade(params = {}) {
      await this.$services.ApiServices.list('mutation', {
        ...params,
        page: this.page,
        graduate: '1',
        school_uuid: this.school_uuid,
      }).then(
        ({ data }) => {
          this.mutations = data.data.map((mutation, index) => ({
            ...mutation,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
    },
    filterByClass(uuid) {
      this.class_uuid = uuid
      if (uuid === null) {
        this.class_uuid = null
        this.total_student = 0
      }
    },
    async addGraduated(status) {
      if (status === 'Lulus') {
        this.isLoadingButtonGraduate = true
      } else {
        this.isLoadingButtonNotGraduate = true
      }

      this.formData = new FormData()
      this.formData.append('school_uuid', this.school_uuid)
      const objTime = new Date()
      const year = objTime.getFullYear()
      const month = `0${objTime.getMonth() + 1}`
      const date = `0${objTime.getDate()}`
      const time = `${year}-${month.slice(-2)}-${date.slice(-2)}`
      this.selected.forEach(el => {
        this.formData.append('student_uuid[]', el)
        this.formData.append('status[]', status)
        this.formData.append('reason[]', status)
        this.formData.append('out_date[]', time)
      })

      await this.$services.ApiServices.add('mutationmany', this.formData).then(({ data }) => {
        this.showSnackbar({
          text: data.result.message,
          color: 'success',
        })
      })
      this.modalDialog = false

      if (status === 'Lulus') {
        this.isLoadingButtonGraduate = false
      } else {
        this.isLoadingButtonNotGraduate = false
      }
      await this.listMutationGrade()
      await this.listStudent()
    },
    handleCheckboxChange(studentUuid, selected) {
      this.total_student = selected.length
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },
    async showFormHandlePoint(item, uuid, dialog) {
      if (dialog === 'add') {
        this.dialog = 'add'
        this.subjects = [
          { subject: '', grade: '' },
          { subject: '', grade: '' },
          { subject: '', grade: '' },
          { subject: '', grade: '' },
          { subject: '', grade: '' },
        ]
        await this.$services.ApiServices.list('mutationgrade', { mutation_uuid: item.uuid }).then(({ data }) => {
          this.mutationGrade = data.student
        })
      } else {
        this.dialog = 'edit'
        await this.$services.ApiServices.list('mutationgrade', { mutation_uuid: item.uuid }).then(({ data }) => {
          this.subjects = data.data
          this.mutationGrade = data.student
        })
      }

      this.modalDialogPoint = true
      this.mutation_uuid = uuid
    },
    async updatePoint() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.subjects.forEach(el => {
        if (el.uuid === undefined) {
          this.formData.append('uuid[]', null)
        } else {
          this.formData.append('uuid[]', el.uuid)
        }
        this.formData.append('subject[]', el.subject)
        this.formData.append('grade[]', el.grade)
      })
      await this.$services.ApiServices.update('mutationgrade', this.formData, this.mutation_uuid).then(({ data }) => {
        this.showSnackbar({
          text: data.result.message,
          color: 'success',
        })
      })
      this.isLoadingButton = false
      this.modalDialogPoint = false
    },
    async showPoint(uuid, params = {}) {
      this.dialog = 'show'
      this.modalDialogShowPoint = true
      await this.$services.ApiServices.list('mutationgrade', { ...params, mutation_uuid: uuid }).then(({ data }) => {
        this.mutationGrades = data.data
        this.mutationGrade = data.student
      })
    },
    showFormEdit() {
      this.dialog = 'edit'
      this.modalDialog = true
    },
    addSubject() {
      this.subjectContent = [...this.subjectContent, { subject: '', grade: '' }]
      this.subjects.push(this.subjectContent)
    },
    async deleteSubject(index, uuid) {
      this.subjects.splice(index, 1)
      if (uuid) {
        await this.$services.ApiServices.destroy(this.service, uuid).then(
          ({ data }) => {
            this.showSnackbar({
              text: data.result.message,
              color: 'error',
            })
          },
          err => {
            console.error(err)
          },
        )
      }
    },
    handlerButton() {
      if (this.dialog === 'add') this.addPoint()
      else this.updatePoint()
    },
    async addPoint() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('mutation_uuid', this.mutation_uuid)
      this.subjects.forEach(el => {
        if (el.subject || el.grade) {
          this.formData.append('subject[]', el.subject)
          this.formData.append('grade[]', el.grade)
        }
      })
      await this.$services.ApiServices.add(this.service, this.formData).then(({ data }) => {
        this.showSnackbar({
          text: data.result.message,
          color: 'success',
        })
      })
      await this.listMutationGrade()
      this.resetForm()
      this.isLoadingButton = false
      this.modalDialogPoint = false
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.mutation.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('mutation', this.mutation.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listStudent()
      await this.listMutationGrade()
    },
    async searchHandler(search) {
      this.filterQuery.search = search
      await this.listMutationGrade(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    autofillDateOut() {
      this.graduate.date_out = new Date().toISOString().substr(0, 10)
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.textfieldHidden::v-deep > .v-input__control > .v-text-field__details {
  display: none;
}
</style>
