/* eslint-disable operator-linebreak */
<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :headers="headers"
          :items="students"
          :items-class="classes"
          :items-gender="genders"
          :page="page"
          :search="search"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :is-loading-button="isLoadingButton"
          :student-filter="studentFilter"
          :import-export="importExport"
          title="Peserta Didik/Siswa"
          subtitle="Halaman untuk menambahkan siswa "
          add-btn="Tambah Data"
          @delete-item="confirmDestroy"
          @edit-item="showFormEdit"
          @add-item="showFormAdd"
          @change-page="paginationHandler"
          @filter-gender="filterByGender($event)"
          @filter-class="filterByClass($event)"
          @live-search="searchHandler"
          @import-item="showFormImport"
          @export-item="exportHandler"
          @show-image="showImage"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!valueCheck"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Peserta Didik/Siswa': 'Edit Peserta Didik/Siswa'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template
        v-slot:body
      >
        <v-tabs
          v-model="tab"
          show-arrows
          fixed-tabs
        >
          <v-tab
            v-for="tabItem in tabs"
            :key="tabItem.title"
          >
            <span>{{ tabItem.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="pt-6 pb-0"
        >
          <v-tab-item :eager="true">
            <mandatory-data
              ref="mandatoryData"
              :edit-uuid.sync="editUuid"
              @change-valid="changeValid"
            ></mandatory-data>
          </v-tab-item>
          <v-tab-item :eager="true">
            <data-student
              ref="dataStudent"
              :edit-uuid.sync="editUuid"
            ></data-student>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDialogImport"
      :width="600"
      :is-loading-button="isLoadingButton"
      header="Import Siswa"
      :is-disabled-button="importFile === null"
      text-button="Tambah"
      @handler-button="importHandler"
      @close-modal="modalDialogImport = false"
      @close-button="modalDialogImport = false"
    >
      <template
        v-slot:body
      >
        <div>
          <FileInput
            v-model="importFile"
            label="File Import"
            outlined
            dense
            :message="message"
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            :accept="accept"
            @input="getFile"
          ></FileInput>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Siswa"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
      <template v-slot:body>
        <p>
          <b>Data yang dihapus termasuk :</b>
        </p>
        <p>
          - Data Siswa
          <br>
          - Data Login Siswa
          <br>
          - Data Rapor
          <br>
          - Data Absensi
          <br>
          - Data Latihan/Ujian/Pelajaran
          <br>
          - Data Pinjam Buku
          <br>
          - Data Evoting
        </p>
        <p>Seluruh data dari Siswa ini akan dihapus <b>PERMANENT</b>. Silahkan ketik <b>"Delete Student"</b> untuk validasi penghapusan Siswa.</p>

        <v-text-field
          v-model="validateDelete"
          label="'Delete Student'"
          dense
          type="text"
          outlined
          :rules="[validation.required]"
        ></v-text-field>
      </template>
    </ConfirmDialog>
    <ImageOverlay
      :visible="modalDialogImage"
      :width="800"
      @close="modalDialogImage = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="modalDialogImage = false"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="student.image"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import FileInput from '@/views/components/FileInput.vue'
import ImageOverlay from '@/views/components/ImageOverlay.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import MainCard from '@/views/components/MainCard.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { integerValidator, required } from '@core/utils/validation'
import DataStudent from './DataStudent.vue'
import MandatoryData from './MandatoryData.vue'

// eslint-disable-next-line object-curly-newline
import {
  mdiClose,
  mdiDeleteOutline,
  mdiDownloadBoxOutline,
  mdiFileImportOutline,
  mdiImageMultipleOutline,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlus,
} from '@mdi/js'

export default {
  name: 'Student',
  components: {
    MandatoryData,
    DataStudent,
    // DataParent,
    // DataGuardian,
    DataTablePagination,
    SkeletonLoaderTable,
    MainCard,
    ImageOverlay,
    ModalDialog,
    ConfirmDialog,
    FileInput,
  },
  data() {
    return {
      validation: {
        required,
        integerValidator,
      },
      message: 'File .xlsx/.excel, max. 2MB',
      accept: '.xlsx',
      validateDelete: null,
      dialog: '',
      search: '',
      modalDialog: false,
      modalDialogImage: false,
      confirmDialog: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      imageOverlay: false,
      width: 1080,
      tab: '',
      tabs: [{ title: 'Data Wajib' }, { title: 'Data Diri (Opsional)' }],
      page: 1,
      totalPages: 0,
      totalItems: 0,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      menu: false,
      editUuid: '',
      deleteUuid: '',
      schools: {},
      school_uuid: '',
      class_uuid: '',
      students: [],
      classes: [],
      student: {
        uuid: '',
        username: '',
        name: '',
        password: '',
        school_uuid: '',
        nis: '',
        nisn: '',
        nik_ktp: '',
        gender: '',
        religion: '',
        ethnic_group: '',
        date_of_birth: '',
        place_of_birth: '',
        weight: '',
        heigh: '',
        blood_type: '',
        hobby: '',
        disease_history: '',
        transport: '',
        home_distance: '',
        is_transfer: 0,
        home_phone: '',
        phone: '',
        point: '',
        photo: '',
        province_uuid: '',
        regencies_uuid: '',
        district_uuid: '',
        address: '',
        rt: '',
        rw: '',
        zip_code: '',
        latitude: '',
        longitude: '',
      },
      headers: [
        { text: 'NO', value: 'index' },
        { text: 'NIS', value: 'nis' },
        { text: 'NISN', value: 'nisn' },
        { text: 'NAMA LENGKAP', value: 'name' },
        { text: 'USERNAME', value: 'username' },
        { text: 'JENIS KELAMIN', value: 'gender' },
        { text: 'KELAS', value: 'class[0].name' },
        { text: 'FOTO', value: 'show-image' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,
        mdiImageMultipleOutline,
        mdiFileImportOutline,
        mdiDownloadBoxOutline,
        mdiClose,
      },
      gender: '',
      genders: [
        { index: 1, value: 'Laki-Laki' },
        { index: 2, value: 'Perempuan' },
      ],
      formData: [],
      exportData: true,
      importExport: true,
      studentFilter: true,
      modalDialogImport: false,
      importFile: null,
      filterQuery: {
        search: '',
      },
      valueCheck: false,
    }
  },
  watch: {
    page: {
      handler() {
        this.listStudent(this.filterQuery)
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.listStudent()
    this.listClass()
  },
  methods: {
    changeValid() {
      this.valueCheck = this.$refs.mandatoryData.$data.formValid
    },

    showFormImport() {
      this.modalDialogImport = true
    },
    async importHandler() {
      if (this.importFile === null) {
        return this.showSnackbar({
          text: 'Please add file first!!!',
          color: 'error',
        })
      }
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('file', this.importFile)
      await this.$services.ImportServices.import('importstudent', this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.importFile = null
          this.isLoadingButton = false
          this.modalDialogImport = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
          this.importFile = null
        },
      )
      await this.listStudent(this.filterQuery)
    },
    async exportHandler() {
      this.isLoadingButton = true
      await this.$services.ExportServices.export('exportstudent').then(
        data => {
          this.showSnackbar({
            text: 'Berhasil download template',
            color: 'success',
          })
          const url = window.URL.createObjectURL(new Blob([data.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'template.xlsx')
          document.body.appendChild(link)
          link.click()
          this.isLoadingButton = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
    },
    getFile(data) {
      this.importFile = data
    },
    async listClass() {
      await this.$services.ApiServices.list('class', { school_uuid: this.school_uuid, per_page: 'all' }).then(
        ({ data }) => {
          this.classes.push(...data.data)
        },
        err => console.error(err),
      )
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }

        if (el.school === null) {
          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
      })

      return true
    },

    resetForm() {
      this.$refs.mandatoryData.resetStudent()
      this.$refs.dataStudent.resetStudent()
      this.editUuid = ''
    },
    async listStudent(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('student', {
        ...params,
        school_uuid: this.school_uuid,
        page: this.page,
        gender: this.gender,
        class: this.class_uuid,
      }).then(
        ({ data }) => {
          this.students = data.data.map((student, index) => ({
            ...student,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
          this.isLoadingData = false
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },
    showFormEdit(uuid) {
      this.dialog = 'edit'
      this.editUuid = uuid
      this.resetForm()
      this.modalDialog = true
    },
    async add() {
      this.isLoadingButton = true

      const provinceUuid = this.$refs.dataStudent.$data.student.province_uuid?.uuid ?? ''
      const regenciesUuid = this.$refs.dataStudent.$data.student.regencies_uuid?.uuid ?? ''
      const districtUuid = this.$refs.dataStudent.$data.student.district_uuid?.uuid ?? ''

      this.$refs.dataStudent.$data.student.province_uuid = provinceUuid
      this.$refs.dataStudent.$data.student.regencies_uuid = regenciesUuid
      this.$refs.dataStudent.$data.student.district_uuid = districtUuid

      if (this.$refs.dataStudent.$data.student.photo === null) {
        delete this.$refs.dataStudent.$data.student.photo
      }
      if (this.$refs.dataStudent.$data.student.province_uuid === '') {
        delete this.$refs.dataStudent.$data.student.province_uuid
      }
      if (this.$refs.dataStudent.$data.student.regencies_uuid === '') {
        delete this.$refs.dataStudent.$data.student.regencies_uuid
      }
      if (this.$refs.dataStudent.$data.student.district_uuid === '') {
        delete this.$refs.dataStudent.$data.student.district_uuid
      }
      // if (this.$refs.dataStudent.$data.student.nis === '') {
      //   delete this.$refs.dataStudent.$data.student.nis
      // }
      // if (this.$refs.dataStudent.$data.student.nisn === '') {
      //   delete this.$refs.dataStudent.$data.student.nisn
      // }
      // if (this.$refs.dataStudent.$data.student.email === '') {
      //   delete this.$refs.dataStudent.$data.student.email
      // }
      // if (this.$refs.dataStudent.$data.student.date_of_birth === '') {
      //   delete this.$refs.dataStudent.$data.student.date_of_birth
      // }

      this.formData = new FormData()

      Object.entries(this.$refs.mandatoryData.$data.student).forEach(([key, item]) => {
        this.formData.append(key, item)
      })

      Object.entries(this.$refs.dataStudent.$data.student).forEach(([key, item]) => {
        if (item === null) {
          item = ''
        }
        this.formData.append(key, item)
      })

      if (this.formData.get('nis') === '') {
        this.formData.delete('nis')
      }
      if (this.formData.get('nisn') === '') {
        this.formData.delete('nisn')
      }
      if (this.formData.get('email') === '') {
        this.formData.delete('email')
      }
      if (this.formData.get('date_of_birth') === '') {
        this.formData.delete('date_of_birth')
      }

      this.formData.append('school_uuid', this.school_uuid)

      await this.$services.ApiServices.add('student', this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listStudent(this.filterQuery)
    },
    async edit(uuid) {
      this.editUuid = uuid
    },
    async update() {
      this.isLoadingButton = true

      const provinceUuid = this.$refs.dataStudent.$data.student.province_uuid.uuid
      const regenciesUuid = this.$refs.dataStudent.$data.student.regencies_uuid.uuid
      const districtUuid = this.$refs.dataStudent.$data.student.district_uuid.uuid

      this.$refs.dataStudent.$data.student.province_uuid = provinceUuid
      this.$refs.dataStudent.$data.student.regencies_uuid = regenciesUuid
      this.$refs.dataStudent.$data.student.district_uuid = districtUuid

      this.formData = new FormData()

      Object.entries(this.$refs.mandatoryData.$data.student).forEach(([key, item]) => {
        this.formData.append(key, item)
        if (item === '' || item === null || typeof item === 'undefined') {
          this.formData.delete(key)
        }
      })

      Object.entries(this.$refs.dataStudent.$data.student).forEach(([key, item]) => {
        if (item === null) {
          item = ''
        }
        this.formData.append(key, item)
        if (item === '' || item === null || typeof item === 'undefined') {
          this.formData.delete(key)
        }
      })

      if (this.formData.get('province_uuid') === 'undefined') {
        this.formData.delete('province_uuid')
      }
      if (this.formData.get('regencies_uuid') === 'undefined') {
        this.formData.delete('regencies_uuid')
      }
      if (this.formData.get('district_uuid') === 'undefined') {
        this.formData.delete('district_uuid')
      }
      if (this.formData.get('nis') === '') {
        this.formData.delete('nis')
      }
      if (this.formData.get('nisn') === '') {
        this.formData.delete('nisn')
      }
      if (this.formData.get('email') === '') {
        this.formData.delete('email')
      }
      if (this.formData.get('date_of_birth') === '') {
        this.formData.delete('date_of_birth')
      }

      this.formData.append('school_uuid', this.school_uuid)

      const oldPhoto = this.$refs.dataStudent.$data.student.photo
      if (typeof oldPhoto && typeof this.formData.get('photo') === 'string') {
        this.formData.delete('photo')
      }

      await this.$services.ApiServices.update('student', this.formData, this.editUuid).then(
        ({ data }) => {
          this.editUuid = ''
          this.$refs.mandatoryData.resetStudent()
          this.$refs.dataStudent.resetStudent()
          this.listStudent(this.filterQuery)
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
    },

    confirmDestroy(uuid) {
      this.validateDelete = null
      this.confirmDialog = true
      this.deleteUuid = uuid
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    async destroy() {
      if (this.validateDelete === null || this.validateDelete !== 'Delete Student') {
        this.showSnackbar({
          text: "Please, type 'Delete Student' first !!! ",
          color: 'error',
        })

        return
      }
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('student', this.deleteUuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listStudent(this.filterQuery)
    },
    filterByClass(uuid) {
      this.class_uuid = uuid
      if (uuid === null) {
        this.class_uuid = ''
      }
      this.listStudent(this.filterQuery)
    },
    filterByGender(gender) {
      this.gender = gender
      if (gender === null) {
        this.gender = ''
      }
      this.listStudent(this.filterQuery)
    },
    searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      this.listStudent(this.filterQuery)
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    showImage(image) {
      this.student.image = image
      this.modalDialogImage = true
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.autocompleteHidden::v-deep > .v-input__control > .v-text-field__details {
  display: none !important;
}
</style>
