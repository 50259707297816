<template>
  <div class="mandatory-data">
    <v-form ref="form">
      <v-row class="py-5">
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="6"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.name"
              label="Nama"
              dense
              outlined
              @change="handleChangeName($event)"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="6"
          cols="6"
          class="py-0"
        >
          <div>
            <v-autocomplete
              v-model="student.class_uuid"
              :items="classes"
              item-text="name"
              item-value="uuid"
              label="Kelas"
              dense
              clearable
              outlined
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-if="error === true"
              v-model="student.username"
              label="Username"
              :rules="[rules.alreadyTaken]"
              dense
              value
              outlined
              :hint="hint"
              persistent-hint
              error
              @change="checkUsername($event)"
            ></v-text-field>
            <v-text-field
              v-else
              v-model="student.username"
              label="Username"
              dense
              value
              outlined
              :hint="hint"
              persistent-hint
              @change="checkUsername($event)"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          class="py-0"
        >
          <div>
            <v-text-field
              v-model="student.password"
              label="Password"
              :append-icon="show ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
              :type="show ? 'text' : 'password'"
              dense
              value
              outlined
              @click:append="show = !show"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import randomString from '@/utils/random-string/randomString'
import { integerValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  props: {
    editUuid: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      error: false,
      hint: '',
      rules: {
        alreadyTaken: () => 'The username has already been taken.',
      },
      show: false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      dialog: false,
      validation: {
        required,
        integerValidator,
      },
      initialStudent: {
        uuid: '',
        class_uuid: '',
        name: '',
        username: '',
        password: '',
      },
      student: { ...this.initialStudent },
      classes: [],
      formValid: false,
    }
  },
  watch: {
    student: {
      handler() {
        const valid = []
        // let requiredField = []
        // if (this.dialog === 'add') {
        //   requiredField = ['name', 'username', 'class_uuid', 'password']
        // } else if (this.dialog === 'edit') {
        //   requiredField = ['name', 'username', 'class_uuid']
        // }
        const requiredField = ['name', 'username', 'class_uuid', 'password']

        Object.entries(this.student).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
        this.$emit('change-valid')
      },
      deep: true,
    },
    editUuid() {
      if (this.editUuid) this.get()
    },
  },
  mounted() {
    this.getSchoolUuid()
    if (this.editUuid) this.get()
    this.listClass()
  },
  methods: {
    resetStudent() {
      this.$refs.form.reset()
      // this.student = this.initialStudent
    },
    async listClass(params = { school_uuid: this.school_uuid }) {
      await this.$services.ApiServices.list('class', { ...params, per_page: 'all' }).then(
        ({ data }) => {
          this.classes = data.data
        },
        err => console.error(err),
      )
    },
    async checkUsername(username) {
      await this.$services.AuthServices.checkUsername(username).then(
        ({ data }) => {
          this.error = false
          this.hint = data.result.message
        },
        err => {
          this.error = true
          this.hint = err.message
          console.error(err)
        },
      )
    },
    handleChangeName() {
      if (this.student.name.length > 3) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(async () => {
          // eslint-disable-next-line operator-linebreak
          const randomUsername = randomString(this.student.name)
          await this.checkUsername(randomUsername)
          this.student.username = randomUsername
        }, 500)
      }
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }

        if (el.school === null) {
          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async get() {
      this.error = false
      this.hint = ''
      await this.$services.ApiServices.get('student', this.editUuid).then(
        ({ data }) => {
          // this.student = {
          //   uuid: data.data.uuid,
          //   class_uuid: data.data.class[0]?.uuid,
          //   name: data.data.name,
          //   username: data.data.username,
          // }
          this.student = { ...data.data }
          this.student.name = data.data.name
          this.student.username = data.data.username
          this.student.class_uuid = data.data.class[0]?.uuid
        },
        err => console.error(err),
      )
    },
  },
}
</script>

<style>
</style>
